import gql from 'graphql-tag';

export default id => gql`
  query {
    getDiscountOfferById(input: { id: "${id}" }) {
      id
      name
      description
      transactionType
      itemId
      discountingModel
      serviceType
      serviceAddOn
      startUnit
      startDuration
      endUnit
      endDuration
      minimumQuantity
      maximumQuantity
      salesChannel
      marketSegment
      accountType
      accountSubType
      startDate
      endDate
      status
      country
      discountingType
      useAccumulatorBalance
      accumulatorId
      discountTriggerName
      effectiveDate
      accountingCode
      ratingTimeTaxation
      offerNameAsTxnDescr
      flatDiscounting{
        discounts{
          index
          currencyId
          amount
          percent
        }
        accumulators{
          index
          accumulatorId
          amount
          duration
          unit
        }
      }
      recurringDiscounting{
        purchaseProration
        cancelProration
        upgradeProration
        downgradeProration
        unit
        frequency
        discounts{
          index
          currencyId
          amount
          percent
        }
        accumulators{
          index
          accumulatorId
          amount
          duration
          unit
        }
        tierDiscounting {
          id
          name
          description
          tierType
          tierReference
          accumulatorId
          tiers {
            index
            maximum
            minimum
            discounts {
              index
              currencyId
              amount
              percent
              flatRate
            }
            accumulators{
              index
              accumulatorId
              amount
              duration
              unit
            }
          }
        }
      }
      customerDiscounting {
        index
        salesChannel
        marketSegment
        accountType
        accountSubType
        discounts{
          index
          currencyId
          amount
          percent
        }
        accumulators{
          index
          accumulatorId
          amount
          duration
          unit
        }
      }
      tierDiscounting {
        id
        name
        description
        discountOfferId
        tierType
        tierReference
        accumulatorId
        volumeTreatment
        evaluationStart
        evaluationEnd
        offsetMonths
        nextDiscountingTerm
        tiers {
          index
          maximum
          minimum
          discounts {
            index
            currencyId
            amount
            percent
            flatRate
          }
          accumulators {
            index
            accumulatorId
            amount
            duration
            unit
          }
        }
      }
      txnDiscounting {
        id
        name
        description
        txnDiscounts {
          rateUnit
          timeUnit
          zoneUnit
          impactUnit
          holidayName
          code
          index
          description
          discounts {
            index
            currencyId
            amount
            percent
          }
          accumulators {
            index
            accumulatorId
            amount
            duration
            unit
          }
        }
      }
      complexDiscounting {
        index
        id
        name
        description
        tierType
        tierReference
        purchaseProration
        cancelProration
        upgradeProration
        downgradeProration
        unit
        frequency
        accumulatorId
        volumeTreatment
        evaluationStart
        evaluationEnd
        offsetMonths
        nextDiscountingTerm
        rateUnit
        timeUnit
        zoneUnit
        impactUnit
        holidayName
        salesChannel
        marketSegment
        accountType
        accountSubType
        discounts {
          index
          currencyId
          amount
          percent
        }
        accumulators {
          index
          accumulatorId
          amount
          duration
          unit
        }
        usageAttributeDiscountingData {
          index
          attribute
          value
        }
        tiers {
          index
          maximum
          minimum
          discounts {
            index
            currencyId
            amount
            percent
            flatRate
          }
          accumulators {
            index
            accumulatorId
            amount
            duration
            unit
          }
        }
      }
      usageAttributeDiscounting {
        id
        name
        description
        index
        usageAttributeDiscountingData {
          index
          attribute
          value
        }
        discounts {
          index
          currencyId
          amount
          percent
        }
        accumulators {
          index
          accumulatorId
          amount
          duration
          unit
        }
      }
    }
  }
`;
