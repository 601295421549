import gql from 'graphql-tag';

export default id => gql`
  query {
    getPriceOfferById(input: { id: "${id}" }) {
      id
      name
      description
      transactionType
      itemId
      pricingModel
      serviceType
      serviceAddOn
      startUnit
      startDuration
      endUnit
      endDuration
      minimumQuantity
      maximumQuantity
      salesChannel
      marketSegment
      accountType
      accountSubType
      startDate
      endDate
      status
      country
      pricingType
      billableServiceId
      omsTaskType
      effectiveDate
      isProvisionable
      accountingCode
      ratingTimeTaxation
      offerNameAsTxnDescr
      provisioningNames {
        index
        name
        attributes {
          index
          name
          value
        }
      }
      flatPricing{
        prices{
          index
          currencyId
          amount
        }
        grants{
          index
          resourceId
          amount
          grantDuration
          grantUnit
        }
        accumulators{
          index
          accumulatorId
          amount
          duration
          unit
        }
      }
      recurringPricing{
        purchaseProration
        cancelProration
        upgradeProration
        downgradeProration
        applyInAdvance
        unit
        frequency
        prices{
          index
          currencyId
          amount
        }
        grants{
          index
          resourceId
          amount
          grantDuration
          grantUnit
        }
        accumulators{
          index
          accumulatorId
          amount
          duration
          unit
        }
        tierPricing {
          id
          name
          description
          tierType
          tierReference
          accumulatorId
          tiers {
            index
            maximum
            minimum
            prices {
              index
              currencyId
              amount
              flatRate
            }
            grants{
              index
              resourceId
              amount
              grantDuration
              grantUnit
            }
            accumulators{
              index
              accumulatorId
              amount
              duration
              unit
            }
          }
        }
      }
      customerPricing {
        index
        salesChannel
        marketSegment
        accountType
        accountSubType
        region
        prices{
          index
          currencyId
          amount
        }
        grants{
          index
          resourceId
          amount
          grantDuration
          grantUnit
        }
        accumulators{
          index
          accumulatorId
          amount
          duration
          unit
        }
      }
      tierPricing {
        id
        name
        description
        priceOfferId
        tierType
        tierReference
        accumulatorId
        volumeTreatment
        evaluationStart
        evaluationEnd
        offsetMonths
        nextPricingTerm
        tiers {
          index
          maximum
          minimum
          prices {
            index
            currencyId
            amount
            flatRate
          }
          grants {
            index
            resourceId
            amount
            grantDuration
            grantUnit
          }
          accumulators {
            index
            accumulatorId
            amount
            duration
            unit
          }
        }
      }
      txnPricing {
        id
        name
        description
        txnPrices {
          rateUnit
          timeUnit
          zoneUnit
          impactUnit
          holidayName
          code
          index
          description
          prices {
            index
            currencyId
            amount
          }
          grants {
            index
            resourceId
            amount
            grantDuration
            grantUnit
          }
          accumulators {
            index
            accumulatorId
            amount
            duration
            unit
          }
        }
      }
      complexPricing {
        index
        id
        name
        description
        tierType
        tierReference
        purchaseProration
        cancelProration
        upgradeProration
        downgradeProration
        unit
        frequency
        accumulatorId
        volumeTreatment
        evaluationStart
        evaluationEnd
        offsetMonths
        nextPricingTerm
        rateUnit
        timeUnit
        zoneUnit
        impactUnit
        holidayName
        salesChannel
        marketSegment
        accountType
        accountSubType
        region
        itemId
        prices {
          index
          currencyId
          amount
        }
        grants {
          index
          resourceId
          amount
          grantDuration
          grantUnit
        }
        accumulators {
          index
          accumulatorId
          amount
          duration
          unit
        }
        usageAttributePricingData {
          index
          attribute
          value
        }
        tiers {
          index
          maximum
          minimum
          prices {
            index
            currencyId
            amount
            flatRate
          }
          grants {
            index
            resourceId
            amount
            grantDuration
            grantUnit
          }
          accumulators {
            index
            accumulatorId
            amount
            duration
            unit
          }
        }
      }
      usageAttributePricing {
        id
        name
        description
        index
        itemId
        usageAttributePricingData {
          index
          attribute
          value
        }
        prices {
          index
          currencyId
          amount
        }
        grants {
          index
          resourceId
          amount
          grantDuration
          grantUnit
        }
        accumulators {
          index
          accumulatorId
          amount
          duration
          unit
        }
      }
    }
  }
`;
