import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, orderBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../../constantsApp/countryList';
import { GenericInput } from '../../common';
import PricingSection from './DiscountSection';

const defaultFlatPricing = {
  discounts: [],
  accumulators: [],
};

const defaultRecurringPricing = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  discounts: [],
  // discounts: [{ index: 1, currencyId: 'USD', amount: null }],
  accumulators: [],
};

const defaultCustomerPricing = {
  index: 1,
  salesChannel: 'NONE',
  marketSegment: 'NONE',
  accountType: 'NONE',
  accountSubType: 'NONE',
  discounts: [],
  accumulators: [],
};

const defaultRecurringTiered = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  unit: 'MONTHS',
  frequency: 1,
  tierDiscounting: [
    {
      id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
      name: '',
      description: '',
      tierType: 'PROSPECTIVE',
      tierReference: 'BALANCE',
      tiers: [
        {
          minimum: '0',
          maximum: '0',
          // discounts: [],
          discounts: [],
          accumulators: [],
        },
      ],
    },
  ],
};

const defaultAttributeBased = {
  index: 1,
  rateUnit: 'NONE',
  timeUnit: 'NONE',
  zoneUnit: 'NONE',
  impactUnit: 'NONE',
  holidayName: 'NONE',
  code: '',
  discounts: [],
  accumulators: [],
};

const defaultTiered = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
  name: '',
  description: '',
  discountOfferId: '',
  tierType: null,
  tierReference: null,
  accumulatorId: null,
  volumeTreatment: 'ACTUAL',
  evaluationStart: 0,
  evaluationEnd: 0,
  offsetMonths: 0,
  nextDiscountingTerm: 0,
  tiers: [
    {
      maximum: '0',
      minimum: '0',
      // discounts: [],
      discounts: [],
      accumulators: [],
      // accumulators: [{ accumulatorId: '', amount: '0', duration: 0, unit: null }],
    },
  ],
};

const defaultUsageAttributeBase = {
  index: 1,
  name: 'usage',
  description: 'usage',
  usageAttributeDiscountingData: [
    {
      attribute: '',
      value: 'NONE',
    },
  ],
  discounts: [],
  accumulators: [],
};

const defaultComplex = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 10000000000000)}`,
  index: 1,
  name: '',
  description: '',
  purchaseProration: null,
  cancelProration: null,
  upgradeProration: null,
  downgradeProration: null,
  unit: null,
  frequency: 1,
};

const mapPricingFromItem = ({
  discountingModel,
  flatDiscounting,
  recurringDiscounting,
  customerDiscounting,
  tierDiscounting,
  txnDiscounting,
  transactionType,
  complexDiscounting,
  pricings,
  isChangeModel,
  usageAttributeDiscounting,
}) => {
  if (discountingModel === 'FLAT')
    return !flatDiscounting || flatDiscounting.length === 0 ? [{ ...defaultFlatPricing }] : flatDiscounting;
  if (discountingModel === 'RECURRING')
    return !recurringDiscounting || recurringDiscounting.length === 0 || isChangeModel
      ? [{ ...defaultRecurringPricing }]
      : recurringDiscounting;
  if (discountingModel === 'CUSTOMER_ATTRIBUTE_BASED')
    return !customerDiscounting || customerDiscounting.length === 0
      ? [{ ...defaultCustomerPricing }]
      : customerDiscounting;
  if (discountingModel === 'RECURRING_TIERED')
    return !recurringDiscounting || recurringDiscounting.length === 0 || isChangeModel
      ? [{ ...defaultRecurringTiered }]
      : recurringDiscounting;
  if (discountingModel === 'TRANSACTION_ATTRIBUTE_BASED')
    return !txnDiscounting || txnDiscounting.length === 0
      ? [{ ...defaultAttributeBased }]
      : txnDiscounting[0].txnDiscounts;
  if (discountingModel === 'TIERED') {
    return !tierDiscounting || !tierDiscounting.length ? [{ ...defaultTiered }] : tierDiscounting;
  }
  if (
    discountingModel === 'COMPLEX' &&
    (transactionType === 'USAGE' || transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING')
  ) {
    return !complexDiscounting || !complexDiscounting.length
      ? [{ ...defaultComplex }]
      : orderBy(complexDiscounting, ['index'], ['asc']);
  }

  if (discountingModel === 'USAGE_ATTRIBUTE_BASED') {
    return !usageAttributeDiscounting || usageAttributeDiscounting.length === 0
      ? [{ ...defaultUsageAttributeBase }]
      : usageAttributeDiscounting;
  }

  return pricings;
};

class DiscountForm extends Component {
  state = {
    discountingModel: '',
    transactionType: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (state.oldItem === props.item) return null;
    return {
      ...props.item,
      pricings: mapPricingFromItem(props.item || {}) || [],
      item: props.item,
      oldItem: props.item,
    };
  }

  render() {
    const {
      creatingMode,
      modeApproveDiscountOffer,
      modeSellableDiscountOffer,
      modeNonSellableDiscountOffer,
      modeSunsetDiscountOffer,
      optionsAccumulatorId,
      optionsGrantId,
      t,
      usageContainerRecordList,
      onCancel,
    } = this.props;
    const { pricings, discountingModel, item, transactionType, useAccumulatorBalance, itemId } = this.state;
    const dataCopy2Create = cloneDeep(item);
    if (dataCopy2Create.id) delete dataCopy2Create.id;
    if (dataCopy2Create.name) delete dataCopy2Create.name;
    if (dataCopy2Create.description) delete dataCopy2Create.description;

    let newStatusOptionsWithPermission = cloneDeep(t('selections:statusPricing')());
    if (modeApproveDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'APPROVED');
    if (modeSellableDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SELLABLE');
    if (modeNonSellableDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'NON_SELLABLE');
    if (modeSunsetDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SUNSET');

    if (modeApproveDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'APPROVED')
      ].isDisabled = true;
    if (modeSellableDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SELLABLE')
      ].isDisabled = true;
    if (modeNonSellableDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'NON_SELLABLE')
      ].isDisabled = true;
    if (modeSunsetDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SUNSET')
      ].isDisabled = true;

    let listTaxCodeFields = [];

    if (itemId) {
      listTaxCodeFields = [
        {
          name: 'taxCode',
          label: 'common:label.taxCode',
          wrapperClass: 'col-md-4',
          readOnly: true,
        },
        {
          label: 'common:label.realTimeTaxation',
          wrapperClass: 'col-md-4 mt-4 pt-1',
          name: 'ratingTimeTaxation',
          type: 'checkbox',
        },
      ];
    }

    const listInputs = [
      {
        label: 'common:label.id',
        wrapperClass: 'col-md-4',
        name: 'id',
      },
      {
        label: 'common:label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'common:label.useNameAsInvoiceDescription',
        wrapperClass: 'col-md-4 mt-4 pt-1',
        name: 'offerNameAsTxnDescr',
        type: 'checkbox',
        tooltipDetails: t('message.useNameAsInvoiceDescriptionTooltip'),
      },
      {
        label: 'common:label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        type: 'textarea',
        required: true,
      },
      {
        name: 'discountingType',
        label: 'common:label.discountingType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:pricingType',
        isClearable: true,
        type: 'select',
        disabled: !creatingMode,
      },
      {
        label: 'common:label.itemId',
        wrapperClass: 'col-md-4',
        name: 'itemId',
        required: true,
        type: 'popup',
        onClick: this.onOpenParentSelector,
        fa: 'fa-external-link',
      },
      ...listTaxCodeFields,
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:serviceType',
        isClearable: true,
        required: true,
      },
      {
        name: 'transactionType',
        label: 'common:label.transactionType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:transactionTypeDiscount',
        required: true,
        isClearable: true,
      },
      {
        name: 'discountingModel',
        label: 'common:label.discountingModel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:discountingModel',
        isClearable: true,
        required: true,
      },
      {
        name: 'status',
        label: 'common:label.status',
        wrapperClass: 'col-md-4',
        type: 'select',
        options: newStatusOptionsWithPermission,
        isClearable: true,
        required: true,
      },
      {
        label: 'common:label.effectiveDate',
        wrapperClass: 'col-md-4',
        name: 'effectiveDate',
        type: 'date',
        required: true,
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.startUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'startUnit',
        tOptions: 'selections:timeUnit',
      },

      {
        label: 'common:label.startDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'startDuration',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.endUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'endUnit',
        tOptions: 'selections:timeUnit',
      },
      {
        label: 'common:label.endDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'endDuration',
      },
      {
        label: 'common:label.minimumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'minimumQuantity',
      },
      {
        label: 'common:label.maximumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'maximumQuantity',
      },
      {
        name: 'salesChannel',
        label: 'common:label.salesChannel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:salesChannel',
        isClearable: true,
      },
      {
        name: 'marketSegment',
        label: 'common:label.marketSegment',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:marketSegment',
        isClearable: true,
      },
      {
        name: 'accountType',
        label: 'common:label.accountType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountType',
        isClearable: true,
      },
      {
        name: 'accountSubType',
        label: 'common:label.accountSubType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountSubType',
        isClearable: true,
      },
      {
        name: 'country',
        label: 'common:label.country',
        wrapperClass: 'col-md-4',
        options: countryList,
        isClearable: true,
        type: 'select',
      },
      {
        label: 'common:label.accountingCode',
        wrapperClass: 'col-md-4',
        name: 'accountingCode',
      },
      {
        label: 'common:label.serviceAddOn',
        wrapperClass: 'col-md-4',
        name: 'serviceAddOn',
      },
      {
        label: 'common:label.externalId',
        wrapperClass: 'col-md-4',
        name: 'externalId',
      },
      {
        label: 'common:label.externalName',
        wrapperClass: 'col-md-4',
        name: 'externalName',
      },
      {
        name: 'useAccumulatorBalance',
        label: 'common:label.useAccumulatorBalance',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:selectBool',
        isClearable: true,
        type: 'select',
        disabled: transactionType !== 'VOLUME_DISCOUNT',
        typeValue: 'boolean',
      },
      {
        label: 'common:label.discountTriggerName',
        wrapperClass: 'col-md-4',
        name: 'discountTriggerName',
        type: 'popup',
        onClick: this.onToggleModalDiscountTrigger,
        fa: 'fa-external-link',
      },
    ];

    if (transactionType === 'VOLUME_DISCOUNT' && (useAccumulatorBalance === 'TRUE' || useAccumulatorBalance === true)) {
      listInputs.push({
        label: 'common:label.accumulatorId',
        wrapperClass: 'col-md-4',
        name: 'accumulatorId',
        isClearable: true,
        type: 'select',
        options: optionsAccumulatorId || [],
      });
    }
    const data = this.state || {};
    return (
      <div>
        <div className="form-row">
          {listInputs.map(input => {
            if (input.name === 'ratingTimeTaxation') {
              return (
                <GenericInput
                  {...input}
                  key={input.name}
                  value={data[input.name] !== false}
                  readOnly
                  disabled
                />
              );
            }
            if (input.typeValue === 'boolean') {
              return (
                <GenericInput
                  {...input}
                  key={input.name}
                  value={data[input.name] === true || data[input.name] === 'TRUE' ? 'TRUE' : 'FALSE'}
                  readOnly
                  disabled
                />
              );
            }
            return <GenericInput {...input} key={input.name} value={data[input.name]} readOnly disabled />;
          })}
          <div className="col-md-12">
            <PricingSection
              pricings={cloneDeep(pricings)}
              discountingModel={discountingModel}
              creatingMode={creatingMode}
              optionsAccumulatorId={optionsAccumulatorId}
              optionsGrantId={optionsGrantId}
              transactionType={transactionType}
              usageContainerRecordList={usageContainerRecordList}
            />
          </div>

          <div className="form-group col-md-12 action-buttons">
            <button onClick={onCancel} type="button" className="button button-border black x-small  float-right mt-3">
              {t('label.cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

DiscountForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
  modeModifyDiscountOffer: PropTypes.number,
  modeApproveDiscountOffer: PropTypes.number,
  modeSellableDiscountOffer: PropTypes.number,
  modeNonSellableDiscountOffer: PropTypes.number,
  modeSunsetDiscountOffer: PropTypes.number,
  optionsAccumulatorId: PropTypes.arrayOf(PropTypes.any),
  optionsGrantId: PropTypes.arrayOf(PropTypes.any),
  t: PropTypes.func,
  onCancel: PropTypes.func,
  usageContainerRecordList: PropTypes.arrayOf(PropTypes.any),
};

DiscountForm.defaultProps = {
  onSubmit: () => {},
  item: {},
  isSubmitting: false,
  creatingMode: true,
  modeModifyDiscountOffer: 2,
  modeApproveDiscountOffer: 2,
  modeSellableDiscountOffer: 2,
  modeNonSellableDiscountOffer: 2,
  modeSunsetDiscountOffer: 2,
  optionsAccumulatorId: [],
  optionsGrantId: [],
  usageContainerRecordList: [],
  t: () => {},
  onCancel: () => {},
};

export default withTranslation(['common'])(DiscountForm);
