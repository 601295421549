import { createSelector } from 'reselect';

const selectReport = state => {
  return state.get('reportReducer');
};

const makeGetListNewCustomer = () => createSelector(selectReport, items => items.get('listNewCustomer'));
const makeGetListAllNewCustomer = () => createSelector(selectReport, items => items.get('listAllNewCustomer'));

const makeGetListInvoicing = () => createSelector(selectReport, items => items.get('listInvoicing'));
const makeGetListAllInvoicing = () => createSelector(selectReport, items => items.get('listAllInvoicing'));

const makeGetListPayments = () => createSelector(selectReport, items => items.get('listPayments'));
const makeGetListAllPayments = () => createSelector(selectReport, items => items.get('listAllPayments'));

const makeGetListBilling = () => createSelector(selectReport, items => items.get('listBilling'));
const makeGetListAllBilling = () => createSelector(selectReport, items => items.get('listAllBilling'));

const makeGetListAgeingSummary = () => createSelector(selectReport, items => items.get('listAgeingSummary'));
const makeGetListAllAgeingSummary = () => createSelector(selectReport, items => items.get('listAllAgeingSummary'));

const makeGetListAccountsClosure = () => createSelector(selectReport, items => items.get('listAccountsClosure'));
const makeGetListAllAccountsClosure = () => createSelector(selectReport, items => items.get('listAllAccountsClosure'));

const makeGetListOrders = () => createSelector(selectReport, items => items.get('listOrders'));
const makeGetListAllOrders = () => createSelector(selectReport, items => items.get('listAllOrders'));

const makeGetListBillProductSummary = () => createSelector(selectReport, items => items.get('listBillProductSummary'));
const makeGetListAllBillProductSummary = () =>
  createSelector(selectReport, items => items.get('listAllBillProductSummary'));

const makeGetListBillTransactionSummary = () =>
  createSelector(selectReport, items => items.get('listBillTransactionSummary'));
const makeGetListAllBillTransactionSummary = () =>
  createSelector(selectReport, items => items.get('listAllBillTransactionSummary'));

const makeGetListJobSchedule = () => createSelector(selectReport, items => items.get('listJobSchedule'));
const makeGetListAllJobSchedule = () => createSelector(selectReport, items => items.get('listAllJobSchedule'));

const makeGetListJobFailed = () => createSelector(selectReport, items => items.get('listJobFailed'));
const makeGetListAllJobFailed = () => createSelector(selectReport, items => items.get('listAllJobFailed'));

const makeGetListTrialSubscription = () => createSelector(selectReport, items => items.get('listTrialSubscription'));
const makeGetListAllTrialSubscription = () =>
  createSelector(selectReport, items => items.get('listAllTrialSubscription'));

const makeGetListSubscriptionExpiry = () => createSelector(selectReport, items => items.get('listSubscriptionExpiry'));
const makeGetListAllSubscriptionExpiry = () =>
  createSelector(selectReport, items => items.get('listAllSubscriptionExpiry'));

const makeGetListCollection030 = () => createSelector(selectReport, items => items.get('listCollection030'));
const makeGetListAllCollection030 = () => createSelector(selectReport, items => items.get('listAllCollection030'));

const makeGetListCollection3060 = () => createSelector(selectReport, items => items.get('listCollection3060'));
const makeGetListAllCollection3060 = () => createSelector(selectReport, items => items.get('listAllCollection3060'));

const makeGetListCollection6090 = () => createSelector(selectReport, items => items.get('listCollection6090'));
const makeGetListAllCollection6090 = () => createSelector(selectReport, items => items.get('listAllCollection6090'));

const makeGetListCollectionMoreThan90 = () =>
  createSelector(selectReport, items => items.get('listCollectionMoreThan90'));
const makeGetListAllCollectionMoreThan90 = () =>
  createSelector(selectReport, items => items.get('listAllCollectionMoreThan90'));

const makeGetListFailedPayment = () => createSelector(selectReport, items => items.get('listFailedPayment'));
const makeGetListAllFailedPayment = () => createSelector(selectReport, items => items.get('listAllFailedPayment'));

const makeGetListPaymentSuspense = () => createSelector(selectReport, items => items.get('listPaymentSuspense'));
const makeGetListAllPaymentSuspense = () => createSelector(selectReport, items => items.get('listAllPaymentSuspense'));

const makeGetListAROpsDetails = () => createSelector(selectReport, items => items.get('listAROpsDetails'));
const makeGetListAllAROpsDetails = () => createSelector(selectReport, items => items.get('listAllAROpsDetails'));

const makeGetListOpenDispute = () => createSelector(selectReport, items => items.get('listOpenDispute'));
const makeGetListAllOpenDispute = () => createSelector(selectReport, items => items.get('listAllOpenDispute'));

const makeGetListOpenWriteOff = () => createSelector(selectReport, items => items.get('listOpenWriteOff'));
const makeGetListAllOpenWriteOff = () => createSelector(selectReport, items => items.get('listAllOpenWriteOff'));

const makeGetListUnbilledTransactions = () =>
  createSelector(selectReport, items => items.get('listUnbilledTransactions'));
const makeGetListAllUnbilledTransactions = () =>
  createSelector(selectReport, items => items.get('listAllUnbilledTransactions'));

const makeGetListBillingInvoicing = () => createSelector(selectReport, items => items.get('listBillingInvoicing'));
const makeGetListAllBillingInvoicing = () =>
  createSelector(selectReport, items => items.get('listAllBillingInvoicing'));

const makeGetListBillingAR = () => createSelector(selectReport, items => items.get('listBillingAR'));
const makeGetListAllBillingAR = () => createSelector(selectReport, items => items.get('listAllBillingAR'));

const makeGetListUsageSummary = () => createSelector(selectReport, items => items.get('listUsageSummary'));
const makeGetListAllUsageSummary = () => createSelector(selectReport, items => items.get('listAllUsageSummary'));

const makeGetListUsageDetail = () => createSelector(selectReport, items => items.get('listUsageDetail'));
const makeGetListAllUsageDetail = () => createSelector(selectReport, items => items.get('listAllUsageDetail'));

const makeGetListUsageReconciliation = () =>
  createSelector(selectReport, items => items.get('listUsageReconciliation'));
const makeGetListAllUsageReconciliation = () =>
  createSelector(selectReport, items => items.get('listAllUsageReconciliation'));

const makeGetListRevenueSummary = () => createSelector(selectReport, items => items.get('listRevenueSummary'));
const makeGetListAllRevenueSummary = () => createSelector(selectReport, items => items.get('listAllRevenueSummary'));

const makeGetListRevenueRecognition = () => createSelector(selectReport, items => items.get('listRevenueRecognition'));
const makeGetListAllRevenueRecognition = () =>
  createSelector(selectReport, items => items.get('listAllRevenueRecognition'));

const makeGetListActiveSubscription = () => createSelector(selectReport, items => items.get('listActiveSubscription'));
const makeGetListAllActiveSubscription = () =>
  createSelector(selectReport, items => items.get('listAllActiveSubscription'));

const makeGetListJobDetail = () => createSelector(selectReport, items => items.get('listJobDetail'));
const makeGetListAllJobDetail = () => createSelector(selectReport, items => items.get('listAllJobDetail'));

const makeGetListTaxSummary = () => createSelector(selectReport, items => items.get('listTaxSummary'));
const makeGetListAllTaxSummary = () => createSelector(selectReport, items => items.get('listAllTaxSummary'));

const makeGetListTaxDetail = () => createSelector(selectReport, items => items.get('listTaxDetail'));
const makeGetListAllTaxDetail = () => createSelector(selectReport, items => items.get('listAllTaxDetail'));

const makeGetListSubscriptionContractExpiry = () =>
  createSelector(selectReport, items => items.get('listSubscriptionContractExpiry'));
const makeGetListAllSubscriptionContractExpiry = () =>
  createSelector(selectReport, items => items.get('listAllSubscriptionContractExpiry'));

const makeGetListTrialToPaidSubscription = () =>
  createSelector(selectReport, items => items.get('listTrialToPaidSubscription'));
const makeGetListAllTrialToPaidSubscription = () =>
  createSelector(selectReport, items => items.get('listAllTrialToPaidSubscription'));

const makeGetListRevenueForecast = () => createSelector(selectReport, items => items.get('listRevenueForecast'));
const makeGetListAllRevenueForecast = () => createSelector(selectReport, items => items.get('listAllRevenueForecast'));

const makeGetListMRRForecast = () => createSelector(selectReport, items => items.get('listMRRForecast'));
const makeGetListAllMRRForecast = () => createSelector(selectReport, items => items.get('listAllMRRForecast'));

// Volume Amount By Usage Type
const makeGetListVolumeAmountByUsageType = () =>
  createSelector(selectReport, items => items.get('listVolumeAmountByUsageType'));
const makeGetListAllVolumeAmountByUsageType = () =>
  createSelector(selectReport, items => items.get('listAllVolumeAmountByUsageType'));

// Volume Amount Operator
const makeGetListVolumeAmountOperator = () =>
  createSelector(selectReport, items => items.get('listVolumeAmountOperator'));
const makeGetListAllVolumeAmountOperator = () =>
  createSelector(selectReport, items => items.get('listAllVolumeAmountOperator'));

// Excess Voice Traffic Report
const makeGetListExcessVoiceTraffic = () => createSelector(selectReport, items => items.get('listExcessVoiceTraffic'));
const makeGetListAllExcessVoiceTraffic = () =>
  createSelector(selectReport, items => items.get('listAllExcessVoiceTraffic'));

// Distination
const makeGetListDistination = () => createSelector(selectReport, items => items.get('listDistination'));
const makeGetListAllDistination = () => createSelector(selectReport, items => items.get('listAllLdDistination'));

// Ld Service Report Data
const makeGetListServiceReportData = () => createSelector(selectReport, items => items.get('listServiceReportData'));
const makeGetListAllServiceReportData = () =>
  createSelector(selectReport, items => items.get('listAllServiceReportData'));

// Raw Interconnect Sms
const makeGetListRawInterconnectSms = () => createSelector(selectReport, items => items.get('listRawInterconnectSms'));
const makeGetListAllRawInterconnectSms = () =>
  createSelector(selectReport, items => items.get('listAllRawInterconnectSms'));

// Raw Interconnect Voice
const makeGetListRawInterconnectVoice = () =>
  createSelector(selectReport, items => items.get('listRawInterconnectVoice'));
const makeGetListAllRawInterconnectVoice = () =>
  createSelector(selectReport, items => items.get('listAllRawInterconnectVoice'));

// New Sms
const makeGetListNewSms = () => createSelector(selectReport, items => items.get('listNewSms'));
const makeGetListAllNewSms = () => createSelector(selectReport, items => items.get('listAllNewSms'));

// Credit Notes
const makeGetListCreditNotes = () => createSelector(selectReport, items => items.get('listCreditNotes'));
const makeGetListAllCreditNotes = () => createSelector(selectReport, items => items.get('listAllCreditNotes'));

// Voice Call Type Summary Report
const makeGetListVoiceCallTypeSummary = () =>
  createSelector(selectReport, items => items.get('listVoiceCallTypeSummary'));
const makeGetListAllVoiceCallTypeSummary = () =>
  createSelector(selectReport, items => items.get('listAllVoiceCallTypeSummary'));

// Internet Operator Data Cost
const makeGetListInternetOperatorDataCost = () =>
  createSelector(selectReport, items => items.get('listInternetOperatorDataCost'));
const makeGetListAllInternetOperatorDataCost = () =>
  createSelector(selectReport, items => items.get('listAllInternetOperatorDataCost'));

// Get Telecom Summary
const makeGetListTelecomSummary = () => createSelector(selectReport, items => items.get('listTelecomSummary'));
const makeGetListAllTelecomSummary = () => createSelector(selectReport, items => items.get('listAllTelecomSummary'));

// Get Telecom Summary
const makeGetListAccountReport = () => createSelector(selectReport, items => items.get('listAccount'));
const makeGetListAllAccountReport = () => createSelector(selectReport, items => items.get('listAllAccount'));

// Raw File
const makeGetListRawFile = () => createSelector(selectReport, items => items.get('listRawFile'));
const makeGetListAllRawFile = () => createSelector(selectReport, items => items.get('listAllRawFile'));

// Usage Transaction Detail
const makeGetListUsageTransactionDetail = () =>
  createSelector(selectReport, items => items.get('listUsageTransactionDetail'));
const makeGetListAllUsageTransactionDetail = () =>
  createSelector(selectReport, items => items.get('listAllUsageTransactionDetail'));

// Usage Transaction Detail
const makeGetListBillableServices = () => createSelector(selectReport, items => items.get('listBillableServices'));
const makeGetListAllBillableServices = () =>
  createSelector(selectReport, items => items.get('listAllBillableServices'));

// Accounting Reconciliation
const makeGetListAccountingReconciliation = () =>
  createSelector(selectReport, items => items.get('listAccountingReconciliation'));
const makeGetListAllAccountingReconciliation = () =>
  createSelector(selectReport, items => items.get('listAllAccountingReconciliation'));

// Raw Cdr Data
const makeGetListRawCdrData = () => createSelector(selectReport, items => items.get('listRawCdrData'));
const makeGetListAllRawCdrData = () => createSelector(selectReport, items => items.get('listAllRawCdrData'));

// Raw Sms Data
const makeGetListRawSmsData = () => createSelector(selectReport, items => items.get('listRawSmsData'));
const makeGetListAllRawSmsData = () => createSelector(selectReport, items => items.get('listAllRawSmsData'));

// NonBroadSoftRawCdrData
const makeGetListRawNonBroadsoft = () => createSelector(selectReport, items => items.get('listNonBroadsoft'));
const makeGetListAllRawNonBroadsoft = () => createSelector(selectReport, items => items.get('listAllNonBroadsoft'));

// ServicesForcecast
const makeGetListServicesForcecast = () => createSelector(selectReport, items => items.get('listServicesForcecast'));
const makeGetListAllServicesForcecast = () =>
  createSelector(selectReport, items => items.get('listAllServicesForcecast'));

// employeeCostingPNL
const makeGetEmployeeCostingPNLt = () => createSelector(selectReport, items => items.get('employeeCostingPNL'));
const makeGetAllEmployeeCostingPNL = () => createSelector(selectReport, items => items.get('allEmployeeCostingPNL'));

// List Billable Services Forecast
const makeGetListBillableServicesForecast = () =>
  createSelector(selectReport, items => items.get('listBillableServicesForecast'));
const makeGetListAllBillableServicesForecast = () =>
  createSelector(selectReport, items => items.get('listAllBillableServicesForecast'));

// Oject File
const makeGetListObjectFiles = () => createSelector(selectReport, items => items.get('listObjectFiles'));
const makeGetListAllObjectFiles = () => createSelector(selectReport, items => items.get('listAllObjectFiles'));

// Provisioning Data
const makeGetListProvisioningData = () => createSelector(selectReport, items => items.get('listProvisioningData'));
const makeGetListAllProvisioningData = () =>
  createSelector(selectReport, items => items.get('listAllProvisioningData'));

// Billing Tracker
const makeGetListBillingTracker = () => createSelector(selectReport, items => items.get('listBillingTracker'));
const makeGetListAllBillingTracker = () => createSelector(selectReport, items => items.get('listAllBillingTracker'));

// employeeCostingYearlyPL
const makeGetEmployeeCostingYearlyPL = () =>
  createSelector(selectReport, items => items.get('employeeCostingYearlyPL'));
const makeGetListAllEmployeeCostingYearlyPL = () =>
  createSelector(selectReport, items => items.get('allEmployeeCostingYearlyPL'));

// CommitmentData
const makeGetListCommitmentData = () => createSelector(selectReport, items => items.get('listCommitmentData'));
const makeGetListAllListCommitmentData = () =>
  createSelector(selectReport, items => items.get('listAllCommitmentData'));

// listAgeingPayment
const makeGetListAgeingPayment = () => createSelector(selectReport, items => items.get('listAgeingPayment'));
const makeGetListAllAgeingPayment = () => createSelector(selectReport, items => items.get('listAllAgeingPayment'));

// listFileProcessingStats
const makeGetListFileProcessingStats = () =>
  createSelector(selectReport, items => items.get('listFileProcessingStats'));
const makeGetListAllFileProcessingStats = () =>
  createSelector(selectReport, items => items.get('listAllFileProcessingStats'));

// listCommissionsCancellation
const makeGetListCommissionsCancellation = () =>
  createSelector(selectReport, items => items.get('listCommissionsCancellation'));
const makeGetListAllCommissionsCancellation = () =>
  createSelector(selectReport, items => items.get('listAllCommissionsCancellation'));

// listCommissionsCancellation
const makeGetListFolioDataExtract = () => createSelector(selectReport, items => items.get('listFolioDataExtract'));
const makeGetListAllFolioDataExtract = () =>
  createSelector(selectReport, items => items.get('listAllFolioDataExtract'));

// getBroadsoftZeroDurationCdrsReport
const makeGetListBroadsoftZeroDurationCdrst = () =>
  createSelector(selectReport, items => items.get('listBroadsoftZeroDurationCdrst'));
const makeGetListAllBroadsoftZeroDurationCdrst = () =>
  createSelector(selectReport, items => items.get('listAllBroadsoftZeroDurationCdrst'));

// getNonBroadsoftZeroDurationCdrsReport
const makeGetListNonBroadsoftZeroDurationCdrs = () =>
  createSelector(selectReport, items => items.get('listNonBroadsoftZeroDurationCdrs'));
const makeGetListAllNonBroadsoftZeroDurationCdrs = () =>
  createSelector(selectReport, items => items.get('listAllNonBroadsoftZeroDurationCdrs'));

// searchDetailRecords
const makeGetListDetailRecords = () => createSelector(selectReport, items => items.get('listDetailRecords'));
const makeGetListAllDetailRecords = () => createSelector(selectReport, items => items.get('listAllDetailRecords'));

// BI Extract
const makeGetListBIExtract = () => createSelector(selectReport, items => items.get('listBIExtract'));
const makeGetListAllBIExtract = () => createSelector(selectReport, items => items.get('listAllBIExtract'));

// RegR00105
const makeGetListRegR00105 = () => createSelector(selectReport, items => items.get('listRegR00105'));
const makeGetListAllRegR00105 = () => createSelector(selectReport, items => items.get('listAllRegR00105'));

// RegR00106
const makeGetListRegR00106 = () => createSelector(selectReport, items => items.get('listRegR00106'));
const makeGetListAllRegR00106 = () => createSelector(selectReport, items => items.get('listAllRegR00106'));

// R00501
const makeGetListRegR00501 = () => createSelector(selectReport, items => items.get('listRegR00501'));
const makeGetListAllRegR00501 = () => createSelector(selectReport, items => items.get('listAllRegR00501'));

// RegR00502
const makeGetListRegR00502 = () => createSelector(selectReport, items => items.get('listRegR00502'));
const makeGetListAllRegR00502 = () => createSelector(selectReport, items => items.get('listAllRegR00502'));

// RegR00503
const makeGetListRegR00503 = () => createSelector(selectReport, items => items.get('listRegR00503'));
const makeGetListAllRegR00503 = () => createSelector(selectReport, items => items.get('listAllRegR00503'));

// RegR00504
const makeGetListRegR00504 = () => createSelector(selectReport, items => items.get('listRegR00504'));
const makeGetListAllRegR00504 = () => createSelector(selectReport, items => items.get('listAllRegR00504'));

// RegR00505
const makeGetListRegR00505 = () => createSelector(selectReport, items => items.get('listRegR00505'));
const makeGetListAllRegR00505 = () => createSelector(selectReport, items => items.get('listAllRegR00505'));

// RegR00506
const makeGetListRegR00506 = () => createSelector(selectReport, items => items.get('listRegR00506'));
const makeGetListAllRegR00506 = () => createSelector(selectReport, items => items.get('listAllRegR00506'));

// RegR00401
const makeGetListRegR00401 = () => createSelector(selectReport, items => items.get('listRegR00401'));
const makeGetListAllRegR00401 = () => createSelector(selectReport, items => items.get('listAllRegR00401'));

// RegR00405
const makeGetListRegR00405 = () => createSelector(selectReport, items => items.get('listRegR00405'));
const makeGetListAllRegR00405 = () => createSelector(selectReport, items => items.get('listAllRegR00405'));

// CreditBureau
const makeGetListCreditBureau = () => createSelector(selectReport, items => items.get('listRegCreditBureau'));
const makeGetListAllCreditBureau = () => createSelector(selectReport, items => items.get('listAllRegCreditBureau'));

// Tax Exemption
const makeGetListTaxExemption = () => createSelector(selectReport, items => items.get('listTaxExemption'));
const makeGetListAllTaxExemption = () => createSelector(selectReport, items => items.get('listAllTaxExemption'));

// Fonatel Report
const makeGetListFonatelReport = () => createSelector(selectReport, items => items.get('listFonatelReport'));
const makeGetListAllFonatelReport = () => createSelector(selectReport, items => items.get('listAllFonatelReport'));

// PHC Report
const makeGetListPhcAssetReport = () => createSelector(selectReport, items => items.get('listPhcAssetReport'));
const makeGetListAllPhcAssetReport = () => createSelector(selectReport, items => items.get('listAllPhcAssetReport'));

// PHC Report
const makeGetListServiceReconReport = () => createSelector(selectReport, items => items.get('listServiceReconReport'));
const makeGetListAllServiceReconReport = () =>
  createSelector(selectReport, items => items.get('listAllServiceReconReport'));

// employeeCostingYearlyPL
const makeGetEmployeeCostingQuarterlyPNL = () =>
  createSelector(selectReport, items => items.get('employeeCostingQuarterlyPNL'));
const makeGetListAllEmployeeCostingQuarterlyPNL = () =>
  createSelector(selectReport, items => items.get('allEmployeeCostingQuarterlyPNL'));

// UsageConsumptionReport
const makeGetUsageConsumptionReport = () =>
  createSelector(selectReport, items => items.get('listUsageConsumptionReport'));
const makeGetListAllUsageConsumptionReport = () =>
  createSelector(selectReport, items => items.get('listAllUsageConsumptionReport'));

// UsageAverageConsumptionReport
const makeGetUsageAverageConsumptionReport = () =>
  createSelector(selectReport, items => items.get('listUsageAverageConsumptionReport'));
const makeGetListAllUsageAverageConsumptionReport = () =>
  createSelector(selectReport, items => items.get('listAllUsageAverageConsumptionReport'));

// getEnergyTopupReport
const makeGetEnergyTopupReport = () => createSelector(selectReport, items => items.get('listEnergyTopupReport'));
const makeGetListAllEnergyTopupReport = () =>
  createSelector(selectReport, items => items.get('listAllEnergyTopupReport'));

// Top Up
const makeGetListTopUp = () => createSelector(selectReport, items => items.get('listTopUp'));
const makeGetListAllTopUp = () => createSelector(selectReport, items => items.get('listAllTopUp'));

export {
  makeGetListNewCustomer,
  makeGetListAllNewCustomer,
  makeGetListInvoicing,
  makeGetListAllInvoicing,
  makeGetListPayments,
  makeGetListAllPayments,
  makeGetListBilling,
  makeGetListAllBilling,
  makeGetListAgeingSummary,
  makeGetListAllAgeingSummary,
  makeGetListAccountsClosure,
  makeGetListAllAccountsClosure,
  makeGetListOrders,
  makeGetListAllOrders,
  makeGetListBillProductSummary,
  makeGetListAllBillProductSummary,
  makeGetListBillTransactionSummary,
  makeGetListAllBillTransactionSummary,
  makeGetListJobSchedule,
  makeGetListAllJobSchedule,
  makeGetListJobFailed,
  makeGetListAllJobFailed,
  makeGetListTrialSubscription,
  makeGetListAllTrialSubscription,
  makeGetListSubscriptionExpiry,
  makeGetListAllSubscriptionExpiry,
  makeGetListCollection030,
  makeGetListAllCollection030,
  makeGetListCollection3060,
  makeGetListAllCollection3060,
  makeGetListCollection6090,
  makeGetListAllCollection6090,
  makeGetListCollectionMoreThan90,
  makeGetListAllCollectionMoreThan90,
  makeGetListFailedPayment,
  makeGetListAllFailedPayment,
  makeGetListPaymentSuspense,
  makeGetListAllPaymentSuspense,
  makeGetListAROpsDetails,
  makeGetListAllAROpsDetails,
  makeGetListOpenDispute,
  makeGetListAllOpenDispute,
  makeGetListOpenWriteOff,
  makeGetListAllOpenWriteOff,
  makeGetListUnbilledTransactions,
  makeGetListAllUnbilledTransactions,
  makeGetListBillingInvoicing,
  makeGetListAllBillingInvoicing,
  makeGetListBillingAR,
  makeGetListAllBillingAR,
  makeGetListUsageSummary,
  makeGetListAllUsageSummary,
  makeGetListUsageDetail,
  makeGetListAllUsageDetail,
  makeGetListUsageReconciliation,
  makeGetListAllUsageReconciliation,
  makeGetListRevenueSummary,
  makeGetListAllRevenueSummary,
  makeGetListAllRevenueRecognition,
  makeGetListRevenueRecognition,
  makeGetListActiveSubscription,
  makeGetListAllActiveSubscription,
  makeGetListJobDetail,
  makeGetListAllJobDetail,
  makeGetListTaxSummary,
  makeGetListAllTaxSummary,
  makeGetListTaxDetail,
  makeGetListAllTaxDetail,
  makeGetListSubscriptionContractExpiry,
  makeGetListAllSubscriptionContractExpiry,
  makeGetListTrialToPaidSubscription,
  makeGetListAllTrialToPaidSubscription,
  makeGetListRevenueForecast,
  makeGetListAllRevenueForecast,
  makeGetListMRRForecast,
  makeGetListAllMRRForecast,
  makeGetListVolumeAmountByUsageType,
  makeGetListAllVolumeAmountByUsageType,
  makeGetListVolumeAmountOperator,
  makeGetListAllVolumeAmountOperator,
  makeGetListExcessVoiceTraffic,
  makeGetListAllExcessVoiceTraffic,
  makeGetListDistination,
  makeGetListAllDistination,
  makeGetListServiceReportData,
  makeGetListAllServiceReportData,
  makeGetListNewSms,
  makeGetListAllNewSms,
  makeGetListRawInterconnectSms,
  makeGetListAllRawInterconnectSms,
  makeGetListRawInterconnectVoice,
  makeGetListAllRawInterconnectVoice,
  makeGetListCreditNotes,
  makeGetListAllCreditNotes,
  makeGetListVoiceCallTypeSummary,
  makeGetListAllVoiceCallTypeSummary,
  makeGetListInternetOperatorDataCost,
  makeGetListAllInternetOperatorDataCost,
  makeGetListTelecomSummary,
  makeGetListAllTelecomSummary,
  makeGetListAccountReport,
  makeGetListAllAccountReport,
  makeGetListRawFile,
  makeGetListAllRawFile,
  makeGetListUsageTransactionDetail,
  makeGetListAllUsageTransactionDetail,
  makeGetListBillableServices,
  makeGetListAllBillableServices,
  makeGetListAccountingReconciliation,
  makeGetListAllAccountingReconciliation,
  makeGetListRawCdrData,
  makeGetListAllRawCdrData,
  makeGetListRawSmsData,
  makeGetListAllRawSmsData,
  makeGetListRawNonBroadsoft,
  makeGetListAllRawNonBroadsoft,
  makeGetListServicesForcecast,
  makeGetListAllServicesForcecast,
  makeGetEmployeeCostingPNLt,
  makeGetAllEmployeeCostingPNL,
  makeGetListBillableServicesForecast,
  makeGetListAllBillableServicesForecast,
  makeGetListObjectFiles,
  makeGetListAllObjectFiles,
  makeGetListProvisioningData,
  makeGetListAllProvisioningData,
  makeGetListBillingTracker,
  makeGetListAllBillingTracker,
  makeGetEmployeeCostingYearlyPL,
  makeGetListAllEmployeeCostingYearlyPL,
  makeGetListCommitmentData,
  makeGetListAllListCommitmentData,
  makeGetListAgeingPayment,
  makeGetListAllAgeingPayment,
  makeGetListFileProcessingStats,
  makeGetListAllFileProcessingStats,
  makeGetListCommissionsCancellation,
  makeGetListAllCommissionsCancellation,
  makeGetListFolioDataExtract,
  makeGetListAllFolioDataExtract,
  makeGetListBroadsoftZeroDurationCdrst,
  makeGetListAllBroadsoftZeroDurationCdrst,
  makeGetListNonBroadsoftZeroDurationCdrs,
  makeGetListAllNonBroadsoftZeroDurationCdrs,
  makeGetListDetailRecords,
  makeGetListAllDetailRecords,
  makeGetListBIExtract,
  makeGetListAllBIExtract,
  makeGetListRegR00105,
  makeGetListAllRegR00105,
  makeGetListRegR00106,
  makeGetListAllRegR00106,
  makeGetListRegR00501,
  makeGetListAllRegR00501,
  makeGetListRegR00502,
  makeGetListAllRegR00502,
  makeGetListRegR00503,
  makeGetListAllRegR00503,
  makeGetListRegR00504,
  makeGetListAllRegR00504,
  makeGetListRegR00505,
  makeGetListAllRegR00505,
  makeGetListRegR00506,
  makeGetListAllRegR00506,
  makeGetListRegR00401,
  makeGetListAllRegR00401,
  makeGetListRegR00405,
  makeGetListAllRegR00405,
  makeGetListCreditBureau,
  makeGetListAllCreditBureau,
  makeGetListTaxExemption,
  makeGetListAllTaxExemption,
  makeGetListFonatelReport,
  makeGetListAllFonatelReport,
  makeGetListPhcAssetReport,
  makeGetListAllPhcAssetReport,
  makeGetListServiceReconReport,
  makeGetListAllServiceReconReport,
  makeGetEmployeeCostingQuarterlyPNL,
  makeGetListAllEmployeeCostingQuarterlyPNL,
  makeGetUsageConsumptionReport,
  makeGetListAllUsageConsumptionReport,
  makeGetUsageAverageConsumptionReport,
  makeGetListAllUsageAverageConsumptionReport,
  makeGetEnergyTopupReport,
  makeGetListAllEnergyTopupReport,
  makeGetListTopUp,
  makeGetListAllTopUp,
};
